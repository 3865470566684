

export const SevenGalaxiesTheme = {
  primary: "rgba(255, 255, 255)",
  background_color: "rgba(255, 255, 255, .3)",
  background_color_transp: "rgba(255, 255, 255, .05)",
  box_shadow_insent: "rgba(255, 255, 255, .8)",
  border: "#fff"
  // background_image: "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(" + SevenG + ")"

}

export const GaiaTheme = {
  primary: "rgba(255, 132, 0)",
  background_color: "rgba(255, 132, 0, .8)",
  background_color_transp: "rgba(255, 132, 0, .1)",
  box_shadow_insent: "rgba(255, 198, 0, .8)",
  border: "#ffc600"
  // background_image: "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(" + Gaia + ")"
}

export const StrojTheme = {
  primary: "rgba(2, 194, 183)",
  background_color: "rgba(2, 194, 183, .8)",
  background_color_transp: "rgba(2, 194, 183, .1)",
  box_shadow_insent: "rgba(0, 255, 210, .8)",
  border: "#00ffd2"
  // background_image: "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(" + Stroj + ")"
}

